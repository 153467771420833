import { Product_Enum } from "types/graphql.types";

export const URL_ACCEPT_OFFER = "/offer/:id";
export const URL_ACCEPT_OFFER_SUCCESS = "/offer/success";

export const URL_UPLOAD_DOCUMENT = "/upload/:id";
export const URL_UPLOAD_DOCUMENT_SUCCESS = "/upload/success";

export const READ_ONLY_VERTICALS = [
  Product_Enum.Fit,
  Product_Enum.Gdpr,
  Product_Enum.Nflx,
  Product_Enum.Nrg,
  Product_Enum.Pkv,
  Product_Enum.Gff,
];
